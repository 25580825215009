<template>
  <div class="main">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="建筑工程施工许可证电子证照信息" name="first">
        <detail-page
          :cards="cards"
          :actions="actions"
        ></detail-page>
      </el-tab-pane>
      <el-tab-pane label="参加单位信息" name="second">
        <transition name="el-fade-in-linear">
          <generic-table
            v-show="activeName === 'second'"
            :headerCellBkcolor="'#F8F8F8'"
            :get-data="getCertificateWorkInfo"
            :columns="displayColumnsProject"
            :page-size="10"
          ></generic-table>
        </transition>
      </el-tab-pane>
      <el-tab-pane label="单体项目信息" name="third">
        <transition name="el-fade-in-linear">
          <generic-table
            v-show="activeName === 'third'"
            :headerCellBkcolor="'#F8F8F8'"
            :get-data="getCertificateProjectInfo"
            :columns="displayColumnsProject1"
            :page-size="10"
          ></generic-table>
        </transition>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import detailPage from '@/components/detail-page'
  import genericTable from '@/components/generic-table'
  import cache from '@/libs/util.cache'
  import {  urlCertificateWorkInfo, urlCertificateProjectInfo } from '@/api/account'
  export default {
    name: "certificateDetails",
    data() {
      return {
        activeName: 'first',
        cards: [],
        actions: [],
        detailsData: cache.getSessionStorage('certificateDetails'),
        displayColumnsProject: [
          { label: "承担角色", prop: "corprolename", minWidth: 25 },
          { label: "单位名称", prop: "corpname", minWidth: 25 },
          { label: "企业统一社会信用代码", prop: "corpcode", minWidth: 25 },
          { label: "负责人姓名", prop: "personname", minWidth: 25 },
          { label: "项目负责人证件号码", prop: "personidcard", minWidth: 25 },
        ],
        displayColumnsProject1: [
          { label: "单体名称", prop: "bname", minWidth: 25 },
          { label: "单体参数", staticData: { value: '地上面积?平方米，地下面积?平方米，地上层数?层，地下层数?层', param: ['housbaarea', 'housbuarea', 'housbafloors', 'housbufloors'] }, minWidth: 25 },
          { label: "单体其他参数", prop: "housbarea", minWidth: 25 },
        ],
      }
    },
    components: { detailPage, genericTable },
    mounted() {
      this.setDetailsData()
    },
    methods: {
      setDetailsData() {
        const { projname, projloc, prjnum, certnum, prjtypenum, provincenum, projowner, projownercode, ownermngr, ownermngrcardno, pstartdate, pcompdate, conprice, projscale, totaluharea, totalaharea, issuauth, issuauthcode, issudate, countynum, rn } = this.detailsData
        this.cards = [{
          colsNum: 2,
          fields: [
            { label: '工程名称', value: projname },
            { label: '建设地址', value: projloc },
            { label: '工程项目编号', value: prjnum },
            { label: '施工许可证电子证照编号', value: certnum },
            { label: '项目分类', value: prjtypenum },
            { label: '项目属地', value: provincenum },
            { label: '建设单位', value: projowner },
            { label: '建设单位代码', value: projownercode },
            { label: '建设单位项目负责人', value: ownermngr },
            { label: '项目负责人证件号码', value: ownermngrcardno },
            { label: '计划开工日期', value: pstartdate },
            { label: '计划竣工日期', value: pcompdate },
            { label: '合同价格(万元)', value: conprice },
            { label: '总面积(平方米)', value: projscale },
            { label: '合计地上面积（平方米）', value: totaluharea },
            { label: '合计地下面积（平方米）', value: totalaharea },
            { label: '发证机关', value: issuauth },
            { label: '发证机关代码', value: issuauthcode },
            { label: '签发日期', value: issudate },
            { label: '管理属地', value: countynum },
            { label: '建设规模', value: rn },
          ]
        }]
      },
      async getCertificateProjectInfo({ size, current }) {
        this.size = size;
        this.current = current;
        const param = {
          current: current,
          relationguid: this.detailsData.rowGu,
          size: size
        }
        const res = await urlCertificateProjectInfo(param)
        const { records, total } = res.data
        return {
          data: records || [],
          total: total || 0,
        }
      },
      async getCertificateWorkInfo({ size, current }) {
        this.size = size;
        this.current = current;
        const param = {
          current: current,
          relationguid: this.detailsData.rowGu,
          size: size
        }
        const res = await urlCertificateWorkInfo(param)
        const { records, total } = res.data
        return {
          data: records || [],
          total: total || 0,
        }
      },
      handleClick(tab, event) {}
    }
  }
</script>

<style scoped lang="less">
  .main {
    padding-bottom: 30px;
  }
</style>
